<template>
  <vx-card class="coach-profile-page"
           :class="{'main-box top-zero-radius': !coachId, 'no-box-shadow': coachId > 0}"
           v-if="user && Object.keys(user).length > 0">

    <profile-main-box :profile="getUserProfile"
                      :default-action="profileStatus"
                      :actionsList="actionsList"
                      @receiveCoachBalance="handleChangeProfileStatus('receiveCoachBalance')"
                      @coachClasses="handleChangeProfileStatus('coachClasses')"
                      @coachActivitiesLog="handleChangeProfileStatus('coachActivitiesLog')">
      <received-coach-balance v-if="profileStatus === 'receiveCoachBalance'" :coach-id="coachId || $route.params.id" @received="handleCoachBalanceReceived"/>

      <coach-classes-list v-if="profileStatus === 'coachClasses'" :coach-id="coachId || $route.params.id"/>

      <coach-activities-log v-if="profileStatus === 'coachActivitiesLog'" :coach-id="coachId || parseInt($route.params.id)" />

      <edit-coach v-if="profileStatus === 'editCoach'" :coach-id="coachId || this.$route.params.id"/>

    </profile-main-box>

    <button v-show="false" id="editCoach" @click="handleChangeProfileStatus('editCoach')"></button>
  </vx-card>
</template>

<script>
import ProfileMainBox from '@/components/profileMainBox/profileMainBox2.vue'
import CustomIcon from '../../../../components/customIcon/customIcon'
import ReceivedCoachBalance from "../receive/receivedCoachBalance";
import CoachClassesList from "../classes/coachClassesList";
import EditCoach from "@/views/admin/coaches/edit/editCoach.vue";
import CoachActivitiesLog from "@/views/admin/coaches/activitiesLog/coachActivitiesLog.vue";
import {addComma, checkUserPermissions, getAvatarUrl} from '@/assets/js/functions'
import {getCoach} from "../../../../http/requests/coaches";

export default {
  name: 'CoachProfile',
  components: {
    CoachActivitiesLog,
    EditCoach,
    CoachClassesList,
    ReceivedCoachBalance,
    CustomIcon,
    ProfileMainBox
  },
  props: {
    coachId: {
      type: [Number, String],
      default: 0
    }
  },
  metaInfo () {
    return {
      title: this.user ? this.$t('coaches.profile.dynamicTitle', {name: `${this.user.general.name.value} ${this.user.general.lastName.value}`}) : this.$t('coaches.profile.title')
    }
  },
  data () {
    return {
      profileStatus: 'coachClasses',
      actions: {
        toolbar: [
          {
            id: {name: 'editCoach', params: {id: this.$route.params.id}},
            type: 'link',
            icon: 'EDIT',
            iconPack: 'useral',
            color: 'warning',
            permission: 'coach.update'
          }
        ],
        leftToolbar: [
          {
            id: {name: 'coaches'},
            type: 'link',
            icon: 'CHEVRON_LEFT',
            iconPack: 'useral'
          }
        ]
      },
      user: null,
      paymentTypes: {
        0: this.$t('coaches.paymentType.daily'),
        1: this.$t('coaches.paymentType.weekly'),
        2: this.$t('coaches.paymentType.monthly'),
      },
      actionsList: [
        // {
        //   event: 'showUserTransactions',
        //   params: {id: this.coachId || this.$route.params.id},
        //   header: this.$t('users.user.profile.actions.transactions'),
        //   preview: {
        //     value: addComma(25000) + this.$locale.currency(),
        //     color: 'success'
        //   },
        //   i18n: '',
        //   icon: 'icon-layers',
        //   iconPack: 'feather'
        // },
        {
          event: 'receiveCoachBalance',
          name: 'receiveCoachBalance',
          i18n: 'coaches.profile.actions.receive',
          icon: 'DOLLAR_SIGN',
          iconPack: 'useral'
        },
        {
          event: 'coachClasses',
          name: 'coachClasses',
          i18n: 'coaches.profile.actions.classes',
          icon: 'CALENDER',
          colorCode: '#000',
          iconPack: 'useral',
        },
        {
          event: 'coachActivitiesLog',
          name: 'coachActivitiesLog',
          params: {id: this.coachId || this.$route.params.id},
          header: this.$t('coaches.profile.actions.logs'),
          i18n: '',
          icon: 'LAST_ACTIVITIES',
          iconPack: 'useral',
          permission: 'activity_logs.show'
        }
      ]
    }
  },
  created () {
    if (!this.coachId) {
      setTimeout(() => {
        this.$store.dispatch('updateNavbarActions', this.actions)
        this.$store.dispatch('updateContentNavbarStyle', 'sticky')
        this.$store.dispatch('updateContentNavbarClass', 'profile-page-navbar mx-auto')
      }, 50)
    }

    this.getUser()
  },
  computed: {
    getUserProfile () {
      const user = {
        avatar: this.user.avatar ? getAvatarUrl(this.user.avatar) : this.user.general.gender === 1 ? require('@/assets/images/man-avatar.png') : require('@/assets/images/woman-avatar.png'),
        name: `${this.user.general.name.value} ${this.user.general.lastName.value}`,
        general: this.user.general,
        importantData: [
          {
            value: this.user.paymentType,
            key: this.$t('coaches.profile.asiderAvatar.paymentType')
          },
          {
            value: `${addComma(this.user.contractPrice) || '0'} ${this.$locale.currency()}`,
            key: this.$t('coaches.profile.asiderAvatar.contractPrice')
          },
          {
            value: `${addComma(this.user.reservePrice) || '0'} ${this.$locale.currency()}`,
            key: this.$t('coaches.profile.asiderAvatar.reservePrice')
          }
        ],
        details: [],
      }

      return user
    },
    getUserTransactions () {
      return {
        value: this.user.financial.balance || 0
      }
    }
  },
  methods: {
    getUser () {

      getCoach(this.coachId || this.$route.params.id).then(res => {
        const coach = res.data.data

        this.user = {
          general: {
            name: {
              value: coach.user.name,
              isInvalid: ''
            },
            lastName: {
              value: coach.user.family,
              isInvalid: ''
            },
            gender: coach.user.gender || '',
            phoneNumber: {
              value: coach.user.phone_number,
              isInvalid: ''
            }
          },
          id: coach.user.id,
          avatar: coach.user.avatar || '',
          contractPrice: coach.contract_price,
          reservePrice: coach.reserve_price,
          paymentType: this.paymentTypes[coach.payment_type]
        }

        const balance_index = this.actionsList.map(e => e.event).indexOf('showUserTransactions')
        let actionsList = JSON.parse(JSON.stringify(this.actionsList))
        if (balance_index > -1) {
          actionsList[balance_index].preview = {
            value: `${addComma(Math.abs(this.user.financial.balance)) || 0} ${this.$locale.currency()}`,
            color: this.user.financial.balance < 0 ? 'success' : this.user.financial.balance > 0 ? 'danger' : ''
          }
        }

        const maxDebt_index = this.actionsList.map(e => e.event).indexOf('setUserMaxDebt')
        if (maxDebt_index > -1) {
          actionsList[maxDebt_index].preview.value = `${addComma(Math.abs(this.user.financial.maxDebt)) || 0} ${this.$locale.currency()}`
        }

        this.actionsList = []
        setTimeout(() => {
          this.actionsList = actionsList
        }, 50)


        if (!this.user.access) {
          this.actionsList.splice(2, 1)
        }

        if (!this.coachId) {
          this.$store.dispatch('setPageTitle', this.$t('users.user.profile.dynamicTitle', {name: `${this.user.general.name.value} ${this.user.general.lastName.value}`}))
        }
      })
    },
    handleChangeProfileStatus(status) {
      if (status !== this.profileStatus) {
        this.profileStatus = ''
        this.$nextTick(() => {
          this.profileStatus = status
        })
      }
    },
    handleCoachBalanceReceived () {
      this.$emit('edited')
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss">
.coach-profile-page {

  .vx-card__body {
    padding: 0 !important;

    .profile-main-box2 {
      padding: 0;
    }
  }
}

.action-buttons {
  display: flex;

  button {
    flex: 1;
    line-height: 40px;
    border-radius: .5rem;
    border: none;
    color: #ffffff;
    transition: all .3s ease;
    cursor: pointer;

    &.payment {
      margin-right: 10px;
      background: #33b000cf;

      &:hover {
        background: #33b000;
      }
    }

    &.cancel {
      background: #b00000af;

      &:hover {
        background: #b00000;
      }
    }
  }
}
</style>
